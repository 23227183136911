// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.delete-button,
.submit-button {
	width: fit-content;
	border-radius: 5px;
	height: 35px;
	color: $white;
	background-color: $brand-color;
	font-size: 1rem;
	padding: 0 20px;
}

.delete-button {
	background-color: $danger;
	align-self: flex-end;
	margin: 10px 50px 10px 0;
}

.submit-button:hover,
.delete-button:hover {
	cursor: pointer;
	background-color: $success;
	color: $text-color;
}