.programs-container {
	width: 80vw;
	min-height: 100px;
	border-bottom: solid 2px $light-grey;
}

.programs-title {
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0 10px;
}

.offers-title-text,
.offers-title-text-main,
.programs-title-text-main {
	margin-right: 50px;
	color: $text-color;
	text-transform: uppercase;
	font-size: 1.2rem;
	border-bottom: solid 1px $mid-grey;
}

.programs-title-text-main {
	font-size: 1.4rem;
	min-width: 15vw;
}

.empty-list {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 20vh;
	font-size: 1.2rem;
	color: $mid-grey;
	font-weight: 300;
}