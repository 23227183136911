.drawer-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	max-width: 100px;
	box-shadow: 5px 0 5px -5px #333;
}

.drawer-header {
	max-width: 150px;
	margin-top: 2vh;
}

.MuiListItemIcon-root.drawer-icon__container {
	min-width: 74px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-to('small') {
		min-width: 56px;
	}
}

.drawer-icon {
	font-size: 1.75rem;
}

.close-sidebar-icon {
	color: #10406B;
}

.sidebar-menu-icon {
	color: #10406B;
}

.drawer-items__container {
	width: 100%;
	background-color: rgb(42, 169, 224);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
	// max-height: calc(100vh - 59px);
	color: rgb(0,0,0);
}

.sidebar-tab-title {
	font-size: 1.2rem;
	color: #fff;
}

.app-bar-text__container {
	width: 100%;
	display: inline-flex;
	justify-content: flex-end;
}