.mutation__container {
	max-width: 85vw;
    min-height: 100px;
}

.mutation__title {
	height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
}

.form__card {
	margin: 50px 20px;
	box-shadow: 7px 4px 20px -2px rgba(69, 69, 69, 0.5);
	min-width: 80vw;
	max-width: 1000px;
	padding: 50px 125px;
}

.form__text-input {

}

.form__input-wrapper {
	width: 100%;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
    margin-bottom: 8px;
}

.pid {
	width: 100%;
}

.add-input-icon {
	margin: 10px;
}

.remove-input-icon {
	color: red;
	margin: 10px;
}

.form__buttons {
	margin: 20px;
	width: 100%;
	padding: 20px 100px;
	display: inline-flex;
	justify-content: flex-end;
}