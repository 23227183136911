.drawer-item {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	padding: 20px;
	font-size: 0.8rem;
	border-bottom: solid 1px $light-grey;
}


.__active {
	background-color: $active-bg;
}

.drawer-item:hover {
	background-color: $hover-bg;
	cursor: pointer;
}