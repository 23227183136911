.accordion-container {
  margin: 50px 20px;
  box-shadow: 7px 4px 20px -2px rgba(69, 69, 69, 0.5)
}

.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion {
  background-color: $white;
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  border-bottom: solid 1px $light-grey;
  min-height: 40px;
}

.accordion__content-left {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.accordion__content-right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.accordion:hover {
  background-color: $active-bg;
  // background-color: $hover-bg;
}

.active,
.active:hover {
  background-color: $active-bg;
}

.accordion__avatar {
  max-height: 30px;
  border-radius: 50%;
  // margin-right: 25px;
}

.accordion__pid_value {
  margin: 0;
  padding: 0 10px;
  font-size: 0.8rem;
  border-right: solid 1px $mid-grey;
  color: $mid-grey;
  // font-weight: bold;
}

.accordion__title {
  font-size: 1.2rem;
  text-align: left;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  padding: 0 20px;
  border-right: solid 1px $mid-grey;
  margin: 5px 0;
  min-height: 40px;
}

// .accrodion__tag {
//   font-size: 0.8rem;
//   letter-spacing: -1px;
//   padding: 0 10px;
//   border-left: solid 1px $mid-grey;
//   border-right: solid 1px $mid-grey;
//   margin-left: 20px;
//   height: 20px;
//   display: flex;
//   align-items: center;
// }

.accrodion__description {
  font-size: 0.8rem;
  max-width: 40vw;
  padding: 0 20px;
  text-align: left;
  // border-left: solid 1px $mid-grey;
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background-color: rgba(200, 200, 200, 0.1);
  overflow: auto;
  transition: height 0.6s ease;
  // -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.25);
  // -moz-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.25);
  // box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.25);
}

.accordion__table-container {
  margin: 0;
  padding: 40px;
  border-bottom: solid 1px #999999;
}

.edit-program {
  margin-left: 20px;
}

.edit-program:hover {
  color: $brand-color;
  cursor: pointer;
}
