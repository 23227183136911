.add-offer-form {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	padding: 20px;
	margin: 15px 50px 15px 50px;
}

.modal-title {
	border-bottom: solid 2px $light-grey;
	text-transform: uppercase;
	font-size: 1.25rem;
	margin: 30px 50px 0 50px;
}

.input{
	width: 100%;
	margin: 0 0 10px 0;
	input{    
	  width: 100%;
	  height: auto;  
	  font-size: 0.75rem;    
	  background-color: transparent !important;
	  border: none;   
	  border-bottom: 2px solid $light-grey;
	  outline: none;
	//   color: #4EA5D9;
	  color: $brand-color;
	  padding-left: 70px;  
	  
	  &:focus {
		padding-left: 10px;
		transition: all .3s;
	  }

	  &:focus + label{
		top: -40px;
		font-size: 0.6rem;
		transition: all .3s;
	  }
	  
	}
	
	label{
	  display: block;
	  position: relative;
	  left: 0;
	  top: -20px;
	  font-size: 0.5rem;
	  cursor: text;
	  color: $mid-grey;    
	  transition: all .3s;    
	}
  }
  
.delete-offer-form {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
}

.remove-item-title {
	font-size: 1.2rem;
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: 10px;
}

.remove-item-text {
	padding-bottom: 10px;
    font-size: 1.6rem;
    border-bottom: solid 2px $light-grey;
	margin-bottom: 25px;
	text-align: center;
}
