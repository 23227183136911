.offer-item__container-main {
	width: 100%;
	background-color: $white;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	margin: 0 0 5px 0;
    text-align: left;
}

.offer-item__container-main:hover {
	border-left: solid 2px #999999;
	cursor: pointer;
}

.offer-item__title {
	font-size: 0.8rem;
	min-width: 200px;
}

.offer-item__property {
	margin: 0 10px;
	min-width: 50px;
	max-width: 100px;
	text-align: center;
	font-size: 0.7rem;
	opacity: 0.8;
}