.offer-panel__container-main {
	background-color: rgba(0,0,0,0.1);
	box-shadow: 5px 5px 15px -5px rgba(69, 69, 69, 0.8);
	padding: 5px 20px 20px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.offer-panel__headers-container {
	width: 100%;
	background-color: #999999;
	color: $white;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 5px 20px;
	margin: 10px 0 5px 0;
	font-size: 0.9rem;
	// -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.25);
  	// -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.25);
  	// box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.offer-panel__list-container {
	max-width: 100%;
}

.add-offer-button__container {
	padding: 10px 10px 0 0;
	font-size: 0.8rem;
	transition: ease-out all 0.4s;
    display: flex;
	justify-content: flex-end;
	align-items: center;
	align-self: flex-end;
}

.add-program-button {
	padding: 10px 10px 0 0;
	font-size: 1rem;
	transition: ease-out all 0.4s;
    display: flex;
	justify-content: flex-end;
	align-items: center;
	min-width: 100px;
	max-width: 20vw;
}

.add-offer-button__container:hover,
.add-program-button:hover {
	cursor: pointer;
	color: $active-bg;
}