.app-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	// overflow-x: scroll;
	// flex-flow: row nowrap;
	// background-color: rgba(0,0,0,0.05);
}

.content-container {
	position: relative;
	top: 0;
	left: 0;
	transition: left 2s;
}

.drawer-open {
	left: 125px;
	width: calc(100% - 150px);
}

.loading-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}